import React, { useCallback, useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useParams, useRouteMatch } from "react-router";
import API from "../../middleware/Api";
import { useDispatch } from "react-redux";
// import Editor from "for-editor";
import Vditor from "vditor"
import { VditorEditor } from "react-vditor"
import SaveButton from "../Dial/Save";
import pathHelper from "../../utils/page";
import TextLoading from "../Placeholder/TextLoading";
import { toggleSnackbar } from "../../redux/explorer";
import UseFileSubTitle from "../../hooks/fileSubtitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginTop: "30px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            marginLeft: theme.spacing(12),
            marginRight: theme.spacing(12),
        },
        marginBottom: 50,
    },
    player: {
        borderRadius: theme.shape.borderRadius,
    },
    root: {
        backgroundColor: "white",
        borderRadius: theme.shape.borderRadius,
    },
    "@global": {
        ".for-toolbar": {
            overflowX: "auto!important",
        },
    },
}));



function useQuery() {
    return new URLSearchParams(useLocation().search);
}

window.mdContent = "";
window.vditor = null;

export default function VditorWrapper() {
    const { t } = useTranslation();
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const math = useRouteMatch();
    const $vm = React.createRef();
    const location = useLocation();
    const query = useQuery();
    const { id } = useParams();
    // const vditorRef = useRef();
    const vditorRef = React.createRef();
    const setVditor = (vditor) => { 
        vditorRef.current = vditor; 
        window.vditor = vditor;
    };
    UseFileSubTitle(query, math, location);

    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );

    useEffect(() => {
        let requestURL = "/file/content/" + query.get("id");
        if (pathHelper.isSharePage(location.pathname)) {
            requestURL = "/share/content/" + id;
            if (query.get("share_path") !== "") {
                requestURL +=
                    "?path=" + encodeURIComponent(query.get("share_path"));
            }
        }

        setLoading(true);
        API.get(requestURL, { responseType: "arraybuffer" })
            .then((response) => {
                const buffer = new Buffer(response.rawData, "binary");
                const textdata = buffer.toString(); // for string
                setContent(textdata);
                window.mdContent = textdata;
                if(vditorRef.current.vditor){
                    // vditorRef.current.clearCache();
                    vditorRef.current.setValue(textdata, true);
                }
            })
            .catch((error) => {
                ToggleSnackbar(
                    "top",
                    "right",
                    t("fileManager.errorReadFileContent", {
                        msg: error.message,
                    }),
                    "error"
                );
            })
            .then(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, [math.params[0]]);

    // useEffect(() => {
    //     if(vditorRef.current?.vditor) {
    //         vditorRef.current.setValue(content);
    //     }
    // }, [vditorRef.current?.vditor]);

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const save = () => {
        setStatus("loading");
        // const saveContent = vditorRef.current.getValue()
        const saveContent = window.vditor.getValue()
        API.put("/file/update/" + query.get("id"), saveContent)
            .then(() => {
                setStatus("success");
                setTimeout(() => setStatus(""), 2000);
            })
            .catch((error) => {
                setStatus("");
                ToggleSnackbar("top", "right", error.message, "error");
            });
    };

    const addImg = async ($file) => {
        $vm.current.$img2Url($file.name, await toBase64($file));
        console.log($file);
    };

    const options = {
        // value: content,
        value: window.mdContent,
        width: '100%',
        height: '100%',
        mode: 'ir',
        tab: '\t',
        fullscreen: {
            index: 99999,
        },
        // input: setContent, 
        focus(value) {
            document.onkeydown = function () {
              // 判断 Ctrl+S
              if (event.ctrlKey == true && event.keyCode == 83) {
                // alert('触发ctrl+s');
                // 或者 return false;
                save()
                event.preventDefault();
              }
            }
        },
        upload: {
            accept: "image/*",
            url: "https://img.lzq1357.site/api/upload",
            multiple: false,
            fieldName:"image",
            setHeaders: function() {
              return {token: "7d75db727ae7f9ba7dfbb69733cfa25e"};
            },
            success: function(editor, response) {
              const resp = JSON.parse(response)
              if(resp.data.url) {
                vditorRef.current.insertValue("![img](" + resp.data.url + ")\r\n")
              } else {
                alert('响应错误：' + resp.code + ' ' + resp.msg);
              }
            },
            error: function(msg) {
              alert(msg);
            },
            // filename(name) {
            //   return name
            //       .replace(`/[^(a-zA-Z0-9\u4e00-\u9fa5\.)]/g`, "")
            //       .replace(`/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g`, "")
            //       .replace(`/\\s/g`, "");
            // },
          },

    };

    const classes = useStyles();
    return (
        <div className={classes.layout} >
                    <VditorEditor 
                        keyID="base-editor"
                        options={options}
                        bindVditor={setVditor} />
            {!pathHelper.isSharePage(location.pathname) && (
                <SaveButton onClick={function(){save()}} status={status} />
            )}
        </div>
    );
}
